import usePageContext from '@/utils/context/page';
import { Box, Container, Flex, useMantineTheme } from '@mantine/core';
import React, { useEffect } from 'react';
import { subscribe, unsubscribe } from '@/helpers/events';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import Currency from '@/helpers/Currency';
import AddToCartPackButton from '@/components/pages/ProductPack/AddToCartPackButton';
import { ProductTemplateProps } from '@/interface.custom';
import { Price } from '@/components/Price/Price';

const PackProductInfoSticky = ({
  price,
  compareAtPrice,
}: {
  price: any;
  compareAtPrice: any;
}) => {
  const [isActive, setIsActive] = React.useState(false);
  const { product, shopifyProduct } =
    usePageContext() as unknown as ProductTemplateProps;
  const theme = useMantineTheme();

  useEffect(() => {
    const fct: EventListenerOrEventListenerObject = (evt) => {
      // @ts-ignore
      if (typeof evt.detail.outOfWindow === 'boolean') {
        // @ts-ignore
        setIsActive(evt.detail.outOfWindow);
      }
    };

    subscribe('header', fct);

    return () => {
      unsubscribe('header', fct);
    };
  }, []);
  return (
    <>
      {isActive && (
        <Box
          py='lg'
          bg='white'
          w='100vw'
          sx={{ position: 'fixed', bottom: 0, zIndex: 1000 }}>
          <Container size={'xl'}>
            <Flex
              align={'center'}
              justify={'space-between'}>
              <Flex align={'center'}>
                <NoozText
                  size={24}
                  fw={500}
                  mr={12}
                  color={'title'}>
                  {`${product.fields.label?.fields.text || ''}`}
                </NoozText>
              </Flex>
              <Flex
                align={'center'}
                gap={16}>
                {price ? (
                  <Price
                    price={price}
                    style={'sticky'}
                    discountApply={!!compareAtPrice}
                  />
                ) : null}
                {compareAtPrice ? (
                  <Price
                    price={compareAtPrice}
                    style={'sticky'}
                    initialPrice
                  />
                ) : null}
                <AddToCartPackButton hasPrice={!!price} />
              </Flex>
            </Flex>
          </Container>
        </Box>
      )}
    </>
  );
};

export default PackProductInfoSticky;
